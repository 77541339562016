<template>
	<div>
		<div class="learn-text">
			<div class="learn-text-box" v-html="row.noticeContent"></div>
		</div>
	</div>
</template>
<script>
export default {
	props: ['noticeContent'],
	data() {
		return {
			row: {}
		}
	},

	components: {},

	computed: {},

	mounted() {},
	watch: {
		noticeContent(newval) {
			this.row = newval
		}
	},
	methods: {}
}
</script>
<style scoped lang="scss">
.learn-text {
	width: 100%;
	padding-top: 65px;
	.learn-text-box {
		width: 1330px;
		margin: 0 auto;
		.text-box-tit {
			text-align: left;
			font-size: 20px;
			font-family: Source Han Sans CN;
			font-weight: 400;
			color: #333333;
			line-height: 30px;
			margin-bottom: 34px;
		}
		.text-box-list {
			text-align: left;
			font-size: 16px;
			font-family: Source Han Sans CN;
			font-weight: 400;
			color: #666666;
			line-height: 30px;
		}
	}
}
</style>
