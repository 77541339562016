<template>
	<div class="learn-head">
		<div class="head-box">
			<div class="text-name">
				<div class="text-eng">{{ headtext.remark }}</div>
				<div class="text-clan">{{ headtext.columnName }}</div>
			</div>
			<div class="page-route">首页/{{ headtext.columnName }}/{{ headText }}</div>
		</div>
	</div>
</template>
<script>
export default {
	props: {
		headText: String
	},
	data() {
		return {
			headtext: {}
			//childNmae: ''
		}
	},
	components: {},
	computed: {
		tabs() {
			return this.$store.state.tabs
		}
	},
	mounted() {
		this.headtext = this.$store.state.tabs
	},
	methods: {},
	watch: {
		tabs: {
			handler(newVla, oldVal) {
				this.headtext = newVla
			},
			deep: true,
			immediate: false
		}
	}
}
</script>
<style scoped lang="scss">
.learn-head {
	width: 100%;
	height: 260px;
	background: url(../static/images/VCG211417596978.png) no-repeat center;
}
.head-box {
	width: 1330px;
	height: 100%;
	margin: 0 auto;
	display: flex;
	justify-content: space-between;
	.text-name {
		text-align: left;
		.text-eng {
			font-size: 40px;
			font-family: Source Han Sans CN;
			font-weight: 500;
			color: #ffffff;
			line-height: 40px;
			margin-top: 82px;
		}
		.text-clan {
			font-size: 40px;
			font-family: Source Han Sans CN;
			font-weight: 500;
			color: #ffffff;
			line-height: 40px;
			position: relative;
			padding-top: 15px;
		}
	}
	.page-route {
		font-size: 14px;
		font-family: Source Han Sans CN;
		font-weight: 400;
		color: #ffffff;
		line-height: 14px;
		display: flex;
		flex-direction: column-reverse;
		padding-bottom: 55px;
	}
}
</style>
