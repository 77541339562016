<template>
	<div class="learn">
		<pagehead :headText="headText"></pagehead>
		<div class="learn-box">
			<ul class="learn-list">
				<li
					v-for="(item, i) in tabList"
					:key="i"
					:class="tabLen == i ? 'learn-active' : ''"
					@click="learnClick(item, i)"
				>
					{{ item.columnName }}
				</li>
			</ul>
		</div>
		<!-- 简介 -->
		<briefIntroduction v-show="tabLen == 0" :noticeContent="noticeContent"></briefIntroduction>
		<rules v-show="tabLen == 1" :noticeContent="noticeContent"></rules>
		<organization v-show="tabLen == 2" :noticeContent="noticeContent"></organization>
	</div>
</template>
<script>
import pagehead from '@/components/Pagehead'
import briefIntroduction from './briefIntroduction.vue' //简介
import rules from './rules.vue' //章程
import organization from './organization.vue' //组织机构
import { listColumn, listInfoByColumnName, listDept, listInfoByColumnId } from '@/api/api.js'
export default {
	data() {
		return {
			parentabs: this.$store.state.tabs, //父级路由信息
			headText: '',
			tabList: [],
			tabLen: 0,
			noticeContent: ''
		}
	},
	props: {},
	components: {
		pagehead,
		briefIntroduction,
		rules,
		organization
	},
	computed: {},
	mounted() {},
	created() {
		let params = {
			parentId: this.parentabs.columnId
		}
		listColumn(params)
			.then(res => {
				this.tabList = res
				this.Getcolumnitem(res[0].columnId)
				this.headText = res[0].columnName
			})
			.catch(error => {
				this.$message.error('获取数据失败！')
			})
	},
	methods: {
		learnClick(item, i) {
			this.tabLen = i
			this.headText = item.columnName

			this.Getcolumnitem(item.columnId)
			// if (item.columnName == '组织机构') {
			// 	this.listdept()
			// } else {
			// 	this.Getcolumnitem(item.columnId)
			// }
		},
		Getcolumnitem(columnId) {
			let params = {
				columnId: columnId
			}
			//listInfoByColumnName(params)
			listInfoByColumnId(params)
				.then(res => {
					this.noticeContent = res[0]
				})
				.catch(error => {
					this.$message.error('获取数据失败！')
				})
		},
		listdept() {
			//获取组织机构 调科室接口
			let params = {}
			listDept(params)
				.then(res => {
					this.noticeContent = res
				})
				.catch(error => {
					this.$message.error('获取数据失败！')
				})
		}
	}
}
</script>
<style scoped lang="scss">
.learn {
	width: 100%;
	padding-bottom: 50px;
	.learn-box {
		width: 100%;
		padding-top: 50px;
		.learn-list {
			margin: 0 auto;
			width: 1330px;
			height: 80px;
			border: 1px solid #d4e6e6;
			display: flex;
			flex-direction: row;
			li {
				flex: 3;
				text-align: center;
				line-height: 80px;
				font-size: 18px;
				font-family: Source Han Sans CN;
				font-weight: 500;
				color: #666666;
				cursor: pointer;
			}
			.learn-active {
				background: #d4e6e6;
				color: #237e80;
			}
		}
	}
}
</style>
