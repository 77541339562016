<template>
	<div class="learn-text">
		<div id="org" v-html="noticeContent.noticeContent"></div>
	</div>
</template>
<script>
export default {
	props: ['noticeContent'],
	data() {
		return {
			org: null,
			treeData: [],
			Echarts: null,
			childLength: []
		}
	},
	components: {},

	computed: {},

	mounted() {},
	watch: {
		// noticeContent(newval) {
		// 	this.treeData = this.toTree(newval)
		// 	this.renderTree()
		// }
	},
	methods: {
		renderTree() {
			let org = this.$echarts.init(document.getElementById('org'))
			this.org = org
			let option = {
				tooltip: {
					trigger: 'item',
					triggerOn: 'mousemove'
				},
				series: [
					{
						type: 'tree',
						data: this.treeData,
						left: '0%',
						right: '0%',
						top: '4%',
						bottom: '20%',
						symbol: 'circle',
						symbolSize: 1,
						orient: 'vertical',
						expandAndCollapse: true,
						lineStyle: {
							color: '#2E8B57',
							curveness: 1 // 修改树的曲度
						},
						label: {
							rotate: 0,
							show: true, // 是否显示标签
							distance: 8, // 文本距离图形元素的距离
							position: ['50%', '50%'], // 标签位置
							verticalAlign: 'middle', // 文字垂直对齐方式，默认自动，top，middle，bottom
							align: 'center', // 文字水平对齐方式，默认自动，left，right，center
							fontSize: 16, // 字体大小
							color: '#fff', // 字体颜色
							backgroundColor: '#288183', // 文字块的背景颜色
							borderColor: '#CED4DA', // 文字块边框颜色
							borderWidth: 1, // 文字块边框宽度
							borderType: 'solid', // 文字块边框描边类型 solid dashed dotted
							borderRadius: 30, // 文字块的圆角
							padding: [10, 28], // 文字块内边距
							//shadowColor: '#288183', // 文字块的背景阴影颜色
							//shadowBlur: 6, // 文字块的背景阴影长度
							formatter: params => {
								if (!params.data.children.length) {
									let newName = ''
									let len = params.data.name.length
									let strLen = 1 //一行显示几个字
									let rowNum = Math.ceil(len / strLen)
									if (len > strLen) {
										for (let p = 0; p < rowNum; p++) {
											let tempStr = ''
											let start = p * strLen
											let end = start + strLen
											if (p == rowNum - 1) {
												tempStr = params.data.name.substring(start, len)
											} else {
												tempStr = params.data.name.substring(start, end) + '\n'
											}
											newName += tempStr
										}
									} else {
										newName = params.data.name
									}
									let n = newName
									if (n.length > 16) {
										n = n.slice(0, 16) + '...'
									}
									let str = `${n}`
									return str
								}
							}
						},
						leaves: {
							normal: {
								position: 'right',
								verticalAlign: 'middle',
								align: 'left',
								formatter: params => {}
							},
							label: {
								position: 'bottom',
								rotate: 0,
								verticalAlign: 'middle',
								align: 'center',
								fontSize: 16,
								padding: [28, 10], // 文字块内边距
								formatter: params => {}
							}
						},
						animationDurationUpdate: 750
					}
				]
			}
			org.setOption(option)
		},
		toTree(data) {
			const result = []
			const map = {}
			if (!Array.isArray(data)) {
				return result
			}
			data.forEach(item => {
				item.children = []
			})
			data.forEach(item => {
				map[item['deptId']] = item
			})
			data.forEach(item => {
				item['name'] = item.deptName
				const parent = map[item['parentId']]
				if (parent) {
					if (!parent.children) {
						parent.children = []
					}
					if (!parent.level) {
						parent.level = 1
					}
					item.level = parent.level + 1
					parent.children.push(item)
				} else {
					result.push(item)
				}
			})
			return result
		}
	}
}
</script>
<style scoped lang="scss">
.learn-text {
	width: 100%;
	padding-top: 65px;
}
#org {
	width: 1330px;
	margin: 0 auto;
}
</style>
